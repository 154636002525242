import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { ThemeProvider } from '@emotion/react';
import { theme } from './theme';
import getData from './data';
import FolderIcon from '@mui/icons-material/Folder'
import DescriptionIcon from '@mui/icons-material/Description';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Typography } from '@mui/material';

function FileButton({value, onFileClick, mode}) {
    console.log(mode)
    switch (mode) {
        
        case "Folder":
            console.log("Loading up a folder button from value: " + mode)
            return (<Button variant="contained" startIcon={<FolderIcon/>} onClick={onFileClick}>{value.replace(/^\/+/g, '')}</Button>)
        case "File":
            return (<Button variant="contained" startIcon={<DescriptionIcon/>} onClick={onFileClick}>{value.replace(/^\/+/g, '')}</Button>)
        case "Back":
            return (<Button variant="contained" startIcon={<ArrowBackIcon/>} onClick={onFileClick}>{value.replace(/^\/+/g, '')}</Button>)
        default:
            return (<Button variant="contained" onClick={onFileClick}>{value.replace(/^\/+/g, '')}</Button>)
    }
};

export default function FileList() {
    
    const [stateLabel, setStateLabel] = useState("Loading...");
    const [files, setFiles] = useState(null);
    const [fetchError, setFetchError] = useState(null);
    const location = useLocation();
    const [displayFile, setDisplayFile] = useState(null);

    useEffect(() => {
        async function fetchData() {
            let result;
            try {
                result = await getData(location.pathname);
            } catch(err) {
                setFetchError("Data server can't be reached!");
                return;
            }
            
            if (result.status == 404) {
                setFetchError(404)
            } 
            if (result.status == 500) {
                setFetchError(500)
            }
            if (!result.ok) {
                setFetchError(result.status)
            }
            const jsonResult = await result.json()
            const tempFileList = []

            if (jsonResult.fileData) {
                // There's filedata to be opened
                setDisplayFile(jsonResult.fileData);
                setFiles([]);
                setStateLabel(null);
                return;
            }
    
            if (jsonResult.files.length > 0) {
                jsonResult.files.map(file => {
                    tempFileList.push(file);
                })
                setFiles(tempFileList)
            } else {
                setFiles([]);
            }
    
            setStateLabel(null);
            setDisplayFile(null);
            console.log("Effect fired!");
        }
        fetchData();
    }, [location.pathname]);

    function handleClick(file) {
        const currentPathName = window.location.pathname.endsWith('/') ? window.location.pathname.slice(0, -1) : window.location.pathname;
        const targetPath = currentPathName + '/' + file.path.replace(/^\/+/g, '');
        window.location.pathname = targetPath;
    };

    function navigateUp() {
        const currentPathName = window.location.pathname.endsWith('/') ? window.location.pathname.slice(0, -1) : window.location.pathname;
        if (currentPathName.replace(' ', '').length == 0) return; // Do nothing if no path to navigate up to
        const targetPath = currentPathName.replace(/\/[^\/]*$/g, '');
        window.location.pathname = targetPath;
    }

    switch(fetchError) {
        case 404:
            return (
                <ThemeProvider theme={theme}>
                    <p>ERROR: 404</p>
                </ThemeProvider>
            )
        case 500:
            return (
                <ThemeProvider theme={theme}>
                    <p>ERROR: 500</p>
                </ThemeProvider>
            )
        case null:
            break;
        default:
            return (
                <ThemeProvider theme={theme}>
                    <p>ERROR: {fetchError}</p>
                </ThemeProvider>
            )

    }

    if (displayFile != null) {

        return (
            <div id="viewerDiv">
                <embed src={`data:${displayFile.mimeType};base64,${displayFile.base64}`} id="viewer"/>
            </div>
        )

    }

    if (files === null) {
        return (
            <ThemeProvider theme={theme}>
                <p>Loading...</p>
            </ThemeProvider>
        );
    }

    const buttonList = files.map(file => {
        if (file.isDirectory) {
            return(<FileButton key={file.path} mode={"Folder"} value={file.path} onFileClick={() => handleClick(file)}/>)
        } else {
            return(<FileButton key={file.path} mode={"File"} value={file.path} onFileClick={() => handleClick(file)}/>)
        }
    }
        
        
    );

    return (
        <ThemeProvider theme={theme}>
            <Stack spacing = {1} padding="20px">
                {(location.pathname.replace('/', '').replace(' ', '').length != 0) ? 
                    <FileButton value={"Back"} onFileClick={() => navigateUp()} mode={"Back"} /> : <Typography>Select a make</Typography>}
                {(buttonList.length > 0) ? buttonList : 'Empty Directory'}
            </Stack>
        </ThemeProvider>
    );

};